// @import '../node_modules/simplebar/dist/simplebar.scss';
// @import '~highlight.js/styles/androidstudio.scss';
// Hop Core
@use '@angular/material' as mat;
@import './@hop/styles/core';
@import './node_modules/hop-calendar/scss/hop-calendar';
// @import '../src/@hop/styles/partials/plugins/quill';
// @import './@hop/styles/partials/plugins/_quill.scss';
/* You can add global styles to this file, and also import other style files */
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legac`y-typography-config());`
@include mat.core();

@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
$my-first-primary: mat.define-palette(mat.$indigo-palette);
$my-first-accent: mat.define-palette(mat.$red-palette, A200, A100, A400);

$my-first-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-first-primary,
      accent: $my-first-accent
    )
  )
);
$my-first-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $my-first-primary,
      accent: $my-first-accent
    )
  )
);
$my-second-primary: mat.define-palette(mat.$green-palette);
$my-second-accent: mat.define-palette(mat.$purple-palette, A200, A100, A400);
$my-second-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-second-primary,
      accent: $my-second-accent
    )
  )
);

$my-third-primary: mat.define-palette(mat.$cyan-palette);
$my-third-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-third-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-third-primary,
      accent: $my-third-accent
    )
  )
);
@include mat.all-legacy-component-themes($my-first-theme);

.green-purple {
  @include mat.all-legacy-component-themes($my-second-theme);
  --color-primary: 76, 175, 80;
}
.cyan-pink {
  @include mat.all-legacy-component-themes($my-third-theme);
  --color-primary: 0, 188, 212;
}
.dark-theme {
  @include mat.all-legacy-component-themes($my-first-dark-theme);
  .backColor {
    background-color: black;
  }
}
// .mat-button-toggle {
//  background-color: mat-color($primary);
// }

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @hop/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/
/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: 92, 119, 1;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
  .blue-snackbar {
    background: #2196f3;
  }

  .red-snackbar {
    background: #f3212f;
  }

  .green-snackbar {
    background: #0ecd2d;
  }
}
