.hop-layout-apollo {
  hop-navbar {
    display: none;
  }

  .navbar-visible {
    hop-navbar {
      display: initial;
    }
    &.navbar-fixed {
      padding-top: 80px;
      hop-navbar {
        nav {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }
}
