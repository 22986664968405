// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
//@import '../../../node_modules/@angular/material/theming';

// SCSS Variables
@import 'var';

// Styles / CSS Variables
//@import 'partials/styles/style-dark';
//@import 'partials/styles/style-light';
@import 'partials/styles/style-default';

// Mixins
@import 'partials/mixins';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat-core($config);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include angular-material-theme($hop-theme);

// Partials
@import 'partials/horizontal';
@import 'partials/vertical';
@import 'partials/print';
@import 'partials/typography';
@import 'partials/overrides';
@import 'partials/scrollbar';
@import 'partials/plugins/angular-material';
@import 'partials/plugins/apexcharts';

// Layouts
@import 'partials/layouts/layout-apollo';
@import 'partials/layouts/layout-ares';
@import 'partials/layouts/layout-hermes';
@import 'partials/layouts/layout-ikaros';
@import 'partials/layouts/layout-zeus';

// Plus imports for other components in your app.

/* You can add global styles to this file, and also import other style files */

/*html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
  overflow: hidden;
}*/

body {
  height: 100%;
  @include mat-typography-level-to-styles($config, body-1);
  @apply text-black leading-normal antialiased;
  font-feature-settings: 'kern';
}

div {
  box-sizing: border-box;
}
